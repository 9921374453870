import React, { Fragment, useState, useEffect } from "react";
import "./Shipping.css";
import { useSelector, useDispatch } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import MetaData from "../layout/Metadata";
import PinDropIcon from "@material-ui/icons/PinDrop";
import HomeIcon from "@material-ui/icons/Home";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";

// import { useAlert } from "react-alert";
import CheckoutSteps from "../Shipping/CheckoutSteps.jsx";

const Shipping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryList = Country.getAllCountries();
//   const alert = useAlert();
  const { shippingInfo } = useSelector((state) => state.cart);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [state, setState] = useState(shippingInfo.state);
  const [country, setCountry] = useState(shippingInfo.country);
  const [pinCode, setPinCode] = useState(shippingInfo.pinCode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [zone, setZone] = useState(shippingInfo.zone);

  const shipping_rates = [
    {
     "SN": 1,
     "Country": "India",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "IN"
    },
    {
     "SN": 2,
     "Country": "Kuwait",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "KW"
    },
    {
     "SN": 3,
     "Country": "Oman",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "OM"
    },
    {
     "SN": 4,
     "Country": "Qatar",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "QA"
    },
    {
     "SN": 5,
     "Country": "Saudi Arabia",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "SA"
    },
    {
     "SN": 6,
     "Country": "United Arab Emirates",
     "Zone": 1,
     "half": 3.8,
     "one": 6.3,
     "oneandhalf": 8.9,
     "two": 11.6,
     "country_code": "AE"
    },
    {
     "SN": 7,
     "Country": "Egypt",
     "Zone": 2,
     "half": 4.4,
     "one": 6.7,
     "oneandhalf": 9.2,
     "two": 11.6,
     "country_code": "EG"
    },
    {
     "SN": 8,
     "Country": "Jordan",
     "Zone": 2,
     "half": 4.4,
     "one": 6.7,
     "oneandhalf": 9.2,
     "two": 11.6,
     "country_code": "JO"
    },
    {
     "SN": 9,
     "Country": "Lebanon",
     "Zone": 2,
     "half": 4.4,
     "one": 6.7,
     "oneandhalf": 9.2,
     "two": 11.6,
     "country_code": "LB"
    },
    {
     "SN": 10,
     "Country": "Pakistan",
     "Zone": 2,
     "half": 4.4,
     "one": 6.7,
     "oneandhalf": 9.2,
     "two": 11.6,
     "country_code": "PK"
    },
    {
     "SN": 11,
     "Country": "Yemen",
     "Zone": 2,
     "half": 4.4,
     "one": 6.7,
     "oneandhalf": 9.2,
     "two": 11.6,
     "country_code": "YE"
    },
    {
     "SN": 12,
     "Country": "Bhutan",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "BT"
    },
    {
     "SN": 13,
     "Country": "China",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "CN"
    },
    {
     "SN": 14,
     "Country": "Hong Kong",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "HK"
    },
    {
     "SN": 15,
     "Country": "Iran",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "IR"
    },
    {
     "SN": 16,
     "Country": "Luxembourg",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "LU"
    },
    {
     "SN": 17,
     "Country": "Macao",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "null"
    },
    {
     "SN": 18,
     "Country": "Malaysia",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "MY"
    },
    {
     "SN": 19,
     "Country": "Nepal",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "NP"
    },
    {
     "SN": 20,
     "Country": "Syria",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "SY"
    },
    {
     "SN": 21,
     "Country": "Taiwan",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "TW"
    },
    {
     "SN": 22,
     "Country": "Turkey",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "TR"
    },
    {
     "SN": 23,
     "Country": "United States",
     "Zone": 3,
     "half": 4.9,
     "one": 7.8,
     "oneandhalf": 11,
     "two": 14.1,
     "country_code": "US"
    },
    {
     "SN": 24,
     "Country": "Belarus",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "BY"
    },
    {
     "SN": 25,
     "Country": "Botswana",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "BW"
    },
    {
     "SN": 26,
     "Country": "Cyprus",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "CY"
    },
    {
     "SN": 27,
     "Country": "Ethiopia",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "ET"
    },
    {
     "SN": 28,
     "Country": "France",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "FR"
    },
    {
     "SN": 29,
     "Country": "Germany",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "DE"
    },
    {
     "SN": 30,
     "Country": "Greece",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "GR"
    },
    {
     "SN": 31,
     "Country": "Italy",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "IT"
    },
    {
     "SN": 32,
     "Country": "Kenya",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "KE"
    },
    {
     "SN": 33,
     "Country": "Liechtenstein",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "LI"
    },
    {
     "SN": 34,
     "Country": "Maldives",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "MV"
    },
    {
     "SN": 35,
     "Country": "Malta",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "MT"
    },
    {
     "SN": 36,
     "Country": "Monaco",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "MC"
    },
    {
     "SN": 37,
     "Country": "Morocco",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "MA"
    },
    {
     "SN": 38,
     "Country": "Netherlands",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "NL"
    },
    {
     "SN": 39,
     "Country": "Philippines",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "PH"
    },
    {
     "SN": 40,
     "Country": "Poland",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "PL"
    },
    {
     "SN": 41,
     "Country": "Portugal",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "PT"
    },
    {
     "SN": 42,
     "Country": "South Africa",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "ZA"
    },
    {
     "SN": 43,
     "Country": "Spain",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "ES"
    },
    {
     "SN": 44,
     "Country": "Sudan",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "SD"
    },
    {
     "SN": 45,
     "Country": "Tanzania",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "TZ"
    },
    {
     "SN": 46,
     "Country": "United Kingdom",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "GB"
    },
    {
     "SN": 47,
     "Country": "Zambia",
     "Zone": 4,
     "half": 5.8,
     "one": 8.4,
     "oneandhalf": 11.3,
     "two": 14.1,
     "country_code": "ZM"
    },
    {
     "SN": 48,
     "Country": "Albania",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "AL"
    },
    {
     "SN": 49,
     "Country": "Azerbaijan",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "AZ"
    },
    {
     "SN": 50,
     "Country": "Barbados",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "BB"
    },
    {
     "SN": 51,
     "Country": "Belgium",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "BE"
    },
    {
     "SN": 52,
     "Country": "Brunei",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "BN"
    },
    {
     "SN": 53,
     "Country": "Cameroon",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "CM"
    },
    {
     "SN": 54,
     "Country": "Canada",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "CA"
    },
    {
     "SN": 55,
     "Country": "Czech Republic",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "CZ"
    },
    {
     "SN": 56,
     "Country": "Denmark",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "DK"
    },
    {
     "SN": 57,
     "Country": "Djibouti",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "DJ"
    },
    {
     "SN": 58,
     "Country": "Finland",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "FI"
    },
    {
     "SN": 59,
     "Country": "Hungary",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "HU"
    },
    {
     "SN": 60,
     "Country": "Ireland",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "IE"
    },
    {
     "SN": 61,
     "Country": "Malawi",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "MW"
    },
    {
     "SN": 62,
     "Country": "Romania",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "RO"
    },
    {
     "SN": 63,
     "Country": "Rwanda",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "RW"
    },
    {
     "SN": 64,
     "Country": "Slovakia",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "SK"
    },
    {
     "SN": 65,
     "Country": "Switzerland",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "CH"
    },
    {
     "SN": 66,
     "Country": "Thailand",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "TH"
    },
    {
     "SN": 67,
     "Country": "Zimbabwe",
     "Zone": 5,
     "half": 7.5,
     "one": 11,
     "oneandhalf": 14.5,
     "two": 18,
     "country_code": "ZW"
    },
    {
     "SN": 68,
     "Country": "Afghanistan",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "AF"
    },
    {
     "SN": 69,
     "Country": "Angola",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "AO"
    },
    {
     "SN": 70,
     "Country": "Japan",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "JP"
    },
    {
     "SN": 71,
     "Country": "Kazakhstan",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "KZ"
    },
    {
     "SN": 72,
     "Country": "Kyrgyzstan",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "KG"
    },
    {
     "SN": 73,
     "Country": "Latvia",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "LV"
    },
    {
     "SN": 74,
     "Country": "Libya",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "LY"
    },
    {
     "SN": 75,
     "Country": "Lithuania",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "LT"
    },
    {
     "SN": 76,
     "Country": "Mali",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "ML"
    },
    {
     "SN": 77,
     "Country": "Mayotte",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "YT"
    },
    {
     "SN": 78,
     "Country": "Moldova",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "MD"
    },
    {
     "SN": 79,
     "Country": "Slovenia",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "SI"
    },
    {
     "SN": 80,
     "Country": "Ukraine",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "UA"
    },
    {
     "SN": 81,
     "Country": "Uzbekistan",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "UZ"
    },
    {
     "SN": 82,
     "Country": "Vietnam",
     "Zone": 6,
     "half": 8,
     "one": 12,
     "oneandhalf": 16,
     "two": 20,
     "country_code": "VN"
    },
    {
     "SN": 83,
     "Country": "Cambodia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "KH"
    },
    {
     "SN": 84,
     "Country": "Comoros",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "KM"
    },
    {
     "SN": 85,
     "Country": "Congo",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "CG"
    },
    {
     "SN": 86,
     "Country": "Equatorial Guinea",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GQ"
    },
    {
     "SN": 87,
     "Country": "Faeroe Islands",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "null"
    },
    {
     "SN": 88,
     "Country": "Gabon",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GA"
    },
    {
     "SN": 89,
     "Country": "Gambia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GM"
    },
    {
     "SN": 90,
     "Country": "Ghana",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GH"
    },
    {
     "SN": 91,
     "Country": "Grenada",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GD"
    },
    {
     "SN": 92,
     "Country": "Guatemala",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GT"
    },
    {
     "SN": 93,
     "Country": "Guinea-Bissau",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "GW"
    },
    {
     "SN": 94,
     "Country": "Indonesia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "ID"
    },
    {
     "SN": 95,
     "Country": "Isle of Man",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "null"
    },
    {
     "SN": 96,
     "Country": "Israel",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "IL"
    },
    {
     "SN": 97,
     "Country": "Liberia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "LR"
    },
    {
     "SN": 98,
     "Country": "Mongolia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "MN"
    },
    {
     "SN": 99,
     "Country": "Montenegro",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "MS"
    },
    {
     "SN": 100,
     "Country": "Mozambique",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "MZ"
    },
    {
     "SN": 101,
     "Country": "Myanmar",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "MM"
    },
    {
     "SN": 102,
     "Country": "Nicaragua",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "NI"
    },
    {
     "SN": 103,
     "Country": "Niger",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "NE"
    },
    {
     "SN": 104,
     "Country": "Serbia",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "RS"
    },
    {
     "SN": 105,
     "Country": "Singapore",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "SG"
    },
    {
     "SN": 106,
     "Country": "Sweden",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "SE"
    },
    {
     "SN": 107,
     "Country": "Tajikistan",
     "Zone": 7,
     "half": 8.7,
     "one": 13.3,
     "oneandhalf": 18,
     "two": 22.6,
     "country_code": "TJ"
    },
    {
     "SN": 108,
     "Country": "Algeria",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "DZ"
    },
    {
     "SN": 109,
     "Country": "Andorra",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "AD"
    },
    {
     "SN": 110,
     "Country": "Antigua and Barbuda",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "AG"
    },
    {
     "SN": 111,
     "Country": "Argentina",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "AR"
    },
    {
     "SN": 112,
     "Country": "Armenia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "AM"
    },
    {
     "SN": 113,
     "Country": "Austria",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "AT"
    },
    {
     "SN": 114,
     "Country": "Bangladesh",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BD"
    },
    {
     "SN": 115,
     "Country": "Belize",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BZ"
    },
    {
     "SN": 116,
     "Country": "Benin",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BJ"
    },
    {
     "SN": 117,
     "Country": "Bolivia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BO"
    },
    {
     "SN": 118,
     "Country": "Bosnia and Herzegovina",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BA"
    },
    {
     "SN": 119,
     "Country": "Brazil",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BR"
    },
    {
     "SN": 120,
     "Country": "Bulgaria",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BG"
    },
    {
     "SN": 121,
     "Country": "Burkina Faso",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BF"
    },
    {
     "SN": 122,
     "Country": "Burundi",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "BI"
    },
    {
     "SN": 123,
     "Country": "Central African Republic",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CF"
    },
    {
     "SN": 124,
     "Country": "Chad",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "TD"
    },
    {
     "SN": 125,
     "Country": "Channel Islands",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "null"
    },
    {
     "SN": 126,
     "Country": "Chile",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CL"
    },
    {
     "SN": 127,
     "Country": "Colombia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CO"
    },
    {
     "SN": 128,
     "Country": "Costa Rica",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CR"
    },
    {
     "SN": 129,
     "Country": "Côte d'Ivoire",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CI"
    },
    {
     "SN": 130,
     "Country": "Croatia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "HR"
    },
    {
     "SN": 131,
     "Country": "Cuba",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "CU"
    },
    {
     "SN": 132,
     "Country": "Dominica",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "DM"
    },
    {
     "SN": 133,
     "Country": "Dominican Republic",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "DO"
    },
    {
     "SN": 134,
     "Country": "El Salvador",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "SV"
    },
    {
     "SN": 135,
     "Country": "Eritrea",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "ER"
    },
    {
     "SN": 136,
     "Country": "Estonia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "EE"
    },
    {
     "SN": 137,
     "Country": "French Guiana",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "GF"
    },
    {
     "SN": 138,
     "Country": "Georgia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "GE"
    },
    {
     "SN": 139,
     "Country": "Gibraltar",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "GI"
    },
    {
     "SN": 140,
     "Country": "Guinea",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "GN"
    },
    {
     "SN": 141,
     "Country": "Guyana",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "GY"
    },
    {
     "SN": 142,
     "Country": "Haiti",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "HT"
    },
    {
     "SN": 143,
     "Country": "Honduras",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "HN"
    },
    {
     "SN": 144,
     "Country": "Iceland",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "IS"
    },
    {
     "SN": 145,
     "Country": "Iraq",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "IQ"
    },
    {
     "SN": 146,
     "Country": "Jamaica",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "JM"
    },
    {
     "SN": 147,
     "Country": "Laos",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "LA"
    },
    {
     "SN": 148,
     "Country": "Lesotho",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "LS"
    },
    {
     "SN": 149,
     "Country": "Madagascar",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "MG"
    },
    {
     "SN": 150,
     "Country": "Mauritania",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "MR"
    },
    {
     "SN": 151,
     "Country": "Mauritius",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "MU"
    },
    {
     "SN": 152,
     "Country": "Mexico",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "MX"
    },
    {
     "SN": 153,
     "Country": "Namibia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "NA"
    },
    {
     "SN": 154,
     "Country": "Nigeria",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "NG"
    },
    {
     "SN": 155,
     "Country": "Norway",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "NO"
    },
    {
     "SN": 156,
     "Country": "Peru",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "PE"
    },
    {
     "SN": 157,
     "Country": "Réunion",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "RE"
    },
    {
     "SN": 158,
     "Country": "Russia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "RU"
    },
    {
     "SN": 159,
     "Country": "San Marino",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "SM"
    },
    {
     "SN": 160,
     "Country": "Seychelles",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "SC"
    },
    {
     "SN": 161,
     "Country": "Somalia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "SO"
    },
    {
     "SN": 162,
     "Country": "Togo",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "TG"
    },
    {
     "SN": 163,
     "Country": "Tunisia",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "TN"
    },
    {
     "SN": 164,
     "Country": "Turkmenistan",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "TM"
    },
    {
     "SN": 165,
     "Country": "Uruguay",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "UY"
    },
    {
     "SN": 166,
     "Country": "Venezuela",
     "Zone": 8,
     "half": 9.2,
     "one": 14.4,
     "oneandhalf": 19.6,
     "two": 24.8,
     "country_code": "VE"
    }
   ];

   const setCountryCode = (e)=>{ 
    console.log('Country code',e.target.value,typeof(e.target.value));
    let tempCountry = shipping_rates.find((country) => country.country_code === e.target.value);
    setZone(tempCountry.Zone);
    let tempFreeShipping = 0;
    console.log('tempCountry',tempCountry);
    // switch (tempCountry.Zone) {
    //   case 1:
    //     tempFreeShipping = 150;
    //     break;
    //   case 2:
    //     tempFreeShipping = 160;
    //     break;
    //   case 3:
    //     tempFreeShipping = 175;
    //     break;
    //   case 4:
    //     tempFreeShipping = 200;
    //     break;
    //   case 5:
    //     tempFreeShipping = 270;
    //     break;
    //   case  6:
    //     tempFreeShipping = 300;
    //     break;
    //   case  7:
    //     tempFreeShipping = 300;
    //     break;
    //   case  8:
    //     tempFreeShipping = 330;
    //     break;
    //   default:
    //     tempFreeShipping = 0;
    // }
    setCountry(e.target.value);
    // if()
   }

  const shippingSubmit = (e) => {
    console.log('Shipping data-->',e);
    console.log('CountryList-->',countryList);
    // e.preventDefault();
    // // this.activeStep = 1;

    // if (phoneNo.length < 8 || phoneNo.length > 8) {
    // //   alert.error("Phone Number should be 10 digits Long");
    //   return;
    // }
    dispatch(
      saveShippingInfo({ address, city, state, country, pinCode, phoneNo, zone })
    );
    navigate("/order/confirm");
  };

  useEffect(() => {
    fetch('src/component/Shipping/shipping_rates.json', {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => console.log('json data--->',data));
  }, []);

  return (
    <Fragment>
      <MetaData title="Shipping Details" />

      <CheckoutSteps activeStep={0} />

      <div className="shippingContainer">
        <div className="shippingBox">
          <h4 className="shippingHeading">Shipping Details</h4>

          <form
            className="shippingForm"
            encType="multipart/form-data"
            onSubmit={shippingSubmit}
          >
            <div>
              <HomeIcon />
              <input
                type="text"
                placeholder="Address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div>
              <LocationCityIcon />
              <input
                type="text"
                placeholder="City"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>

            <div>
              <PinDropIcon />
              <input
                type="number"
                placeholder="Pin Code"
                required
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </div>

            <div>
              <PhoneIcon />
              <input
                type="number"
                placeholder="Phone Number"
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                size="10"
              />
            </div>

            <div>
              <PublicIcon />

              <select
                required
                value={country}
                onChange={(e) => setCountryCode(e)}
              >
                <option value="">Country</option>
                {Country &&
                  Country.getAllCountries().map((item) => (
                    <option key={item.isoCode} value={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* {country && (
              <div>
                <TransferWithinAStationIcon />

                <select
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="">State</option>
                  {State &&
                    State.getStatesOfCountry(country).map((item) => (
                      <option key={item.isoCode} value={item.isoCode}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            )} */}

            <input
              type="submit"
              value="Continue"
              className="shippingBtn s-btn s-btn-2"
            />
            {/* <input
            type="submit"
            value="Continue"
            className="shippingBtn s-btn s-btn-2"
            disabled={state ? false : true}
          /> */}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Shipping;