import React, { Fragment, useState, useEffect } from "react";
import "./ForgotPassword.css";
import Loader from "../layout/Loader/Loader";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../actions/userAction";
// import { useAlert } from "react-alert";
import MetaData from "../layout/Metadata";

const ForgotPassword = () => {
  const dispatch = useDispatch();
//   const alert = useAlert();

  const { error, message, loading } = useSelector(
    (state) => state?.forgotPassword
  );

  const [email, setEmail] = useState("");

  const forgotPasswordSubmit = (e) => {
    e?.preventDefault();
    // console.log('Function called')

    const myForm = new FormData();

    myForm?.set("email", email);
    dispatch(forgotPassword(myForm));
  };

  useEffect(() => {
    // if (error) {
    //   alert?.error(error);
    //   dispatch(clearErrors());
    // }

    // if (message) {
    //   alert?.success(message);
    // }
  }, [dispatch, error, message]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Forgot Password" />
          <div className="forgotPasswordContainer">
            <div className="forgotPasswordBox">
              <h4 className="forgotPasswordHeading">Forgot Password</h4>

              <form
                className="forgotPasswordForm"
                onSubmit={forgotPasswordSubmit}
              >
                <div className="forgotPasswordEmail mb-10">
                  <MailOutlineIcon />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                </div>
                
                <input
                  type="submit"
                  value="Send"
                  className="forgotPasswordBtn s-btn s-btn-2"
                />
              </form>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ForgotPassword;