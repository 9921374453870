import React, { useState, useEffect } from 'react';
import PopImg from "../DiscountPopup/PopImg/image1.jpg";
import axios from 'axios';

function DiscountPopup({ onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showThanksMessage, setShowThanksMessage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isInputHovered, setIsInputHovered] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const popupClosed = localStorage.getItem('popupClosed');
      if (!popupClosed) {
        setShowPopup(true);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [showThanksMessage]);

  const handleClose = () => {
    if (isValidEmail) {
      localStorage.setItem('popupClosed', 'true');
    }
    setShowPopup(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleGetDiscount = async () => {
    try {
      if (validateEmail(email)) {
        const response = await axios.post('https://braveathleisure.com/api/v1/emails', {
          email: email,
        });

        // Check for status 201 Since email is Saving in mongoDB but it should be 200
        if (response && response.status === 201) {
          localStorage.setItem('popupClosed', 'true');
          setShowThanksMessage(true);
          setIsValidEmail(true);
          setEmailSubmitted(true);
        } else {
          console.error('Failed to save email:', response);
        }
      } else {
        setIsValidEmail(false);
        setEmailError(true);
      }
    } catch (error) {
      console.error('Error saving email:', error);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(true);
    setEmailError(false);
  };

  return (
    showPopup && (
      <div style={{ ...styles.popup, zIndex: 9999 }}>
        <div style={styles.content}>
          <div style={styles.row}>
            <div style={styles.column}>
              {showThanksMessage ? (
                <div style={styles.centeredText}>
                  <p style={windowWidth < 376 ? { ...styles.boldText, fontSize: '18px' } : (windowWidth < 321 ? { ...styles.boldText, fontSize: '16px' } : styles.boldText)}>Thanks for signing up!</p>
                  <p>Please check your email for confirmation message!</p>
                </div>
              ) : (
                <>
                  <div style={styles.centeredTextMain}>
                    <p style={windowWidth < 376 ? { ...styles.boldText, fontSize: '18px' } : (windowWidth < 321 ? { ...styles.boldText, fontSize: '8px' } : styles.boldText)}>WANT 15% OFF</p>
                    <p style={windowWidth < 376 ? { ...styles.boldText, fontSize: '18px' } : (windowWidth < 321 ? { ...styles.boldText, fontSize: '8px' } : styles.boldText)}>YOUR FIRST</p>
                    <p style={windowWidth < 376 ? { ...styles.boldText, fontSize: '18px' } : (windowWidth < 321 ? { ...styles.boldText, fontSize: '8px' } : styles.boldText)}>ORDER?</p>
                    {windowWidth >= 427 && (
                      <p style={{ marginTop: '20px', paddingTop: '10px' }}>Leave your email below and we’ll send you the details.</p>
                    )}
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      style={{ ...styles.input, borderColor: isInputHovered ? '#b0ab9e' : (isValidEmail ? '#666' : '') }}
                      value={email}
                      onChange={handleEmailChange}
                      onMouseEnter={() => setIsInputHovered(true)}
                      onMouseLeave={() => setIsInputHovered(false)}
                    />
                    {!isValidEmail && emailError && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
                    {!emailSubmitted && (
                      <button
                        style={{ ...styles.button, color: 'white', backgroundColor: 'black' }}
                        onClick={handleGetDiscount}
                      >
                        GET 15% OFF
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
            <div style={{ ...styles.column, marginLeft: '0px' }}>
              <img src={PopImg} alt="Discount" style={styles.image} />
            </div>
          </div>
          <button style={styles.closeButton} onClick={handleClose}>
            X
          </button>
        </div>
      </div>
    )
  );
}

const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  content: {
    background: 'white',
    borderRadius: '8px',
    position: 'relative',
    textAlign: 'left',
    maxWidth: '650px',
    maxHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    height: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
  },
  column: {
    flex: '1',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  image: {
    maxWidth: '100%',
    width: 'auto',
    height: '100%',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  button: {
    color: 'white',
    backgroundColor: 'black',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    height: 'auto',
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    border: '2px solid #666',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    width: '100%',
    height: 'auto',
    marginBottom: '10px',
    transition: 'border-color 0.3s ease',
  },
  boldText: {
    fontWeight: '800',
    fontSize: '30px',
  },
  centeredText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
  },
  centeredTextMain: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    height: '100%',
    padding: '10px',
    paddingTop: '30px',
  },
};

export default DiscountPopup;
