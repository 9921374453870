import React, { Fragment } from 'react';
import './Faq.css';
import faq_bg from "../../assets/img/faq/faq-bg.jpg";

const Faq = () => {
    document.title = 'Faq | BRAVE Athleisure';
  return (
    <Fragment>

        <main>

            <section class="page__title-area pt-80 pb-65">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xxl-8 col-xl-10">
                            <div class="page__title-wrapper text-center">
                                <span class="page__title-pre">Hi, BRAVE Athleisure Store</span>
                                <h3 class="page__title">FAQ</h3>
                                <p>Welcome to our FAQ section! Here, you'll discover answers to commonly asked questions regarding our products, services, and policies. Whether you're curious about our shipping methods, payment options, or how to place an order, you'll find helpful information to guide you through your shopping experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="faq__bg">
                <div class="container-fluid p-0">
                    <div class="row gx-0">
                        <div class="col-xxl-12">
                            <div class="faq__thumb w-img">
                                <img src={faq_bg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section class="faq__area pt-95 pb-75">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="faq__wrapper">
                                <div class="faq__accordion">
                                    <h3 class="faq__title">Shopping Information</h3>
                                    <div class="accordion" id="faqAccordion">
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq1">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                                                Can I order over the phone?
                                            </button>
                                        </h2>
                                        <div id="faqOne" class="accordion-collapse collapse show" aria-labelledby="faqAccordion" data-bs-parent="#faqAccordion">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Yes, you can place an order over the phone with the assistance of our customer support team. Simply call our toll-free number and one of our representatives will be happy to help you with your purchase. Please have the product details and payment information ready when you call to expedite the ordering process. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq2">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwo" aria-expanded="true" aria-controls="faqTwo">
                                            What Shipping Options Do You Offer?
                                            </button>
                                        </h2>
                                        <div id="faqTwo" class="accordion-collapse collapse" aria-labelledby="faqAccordion" data-bs-parent="#faqAccordion">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>We offer a range of shipping options to accommodate your needs. These may include standard shipping, expedited shipping, and in some cases, same-day delivery depending on your location. During the checkout process, you'll be able to select the shipping method that best suits your preferences and timeline. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThree" aria-expanded="true" aria-controls="faqThree">
                                            Do You Ship Internationally?
                                            </button>
                                        </h2>
                                        <div id="faqThree" class="accordion-collapse collapse" aria-labelledby="faqAccordion" data-bs-parent="#faqAccordion">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Yes, we offer international shipping to many countries around the world. However, please note that shipping times and costs may vary depending on your location. Additionally, certain products may be restricted from international shipment due to customs regulations or other factors. You can check the availability of international shipping during the checkout process or contact our customer support team for more information. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        {/* <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq4">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFour" aria-expanded="true" aria-controls="faqFour">
                                                Pellentesque habitant morbi tristique senectus et netus?
                                            </button>
                                        </h2>
                                        <div id="faqFour" class="accordion-collapse collapse" aria-labelledby="faqAccordion" data-bs-parent="#faqAccordion">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div> */}
                                        {/* <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq5">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFive" aria-expanded="true" aria-controls="faqFive">
                                                Nam pellentesque aliquam metus?
                                            </button>
                                        </h2>
                                        <div id="faqFive" class="accordion-collapse collapse" aria-labelledby="faqAccordion" data-bs-parent="#faqAccordion">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="faq__wrapper faq__wrapper-right">
                                <div class="faq__accordion">
                                    <h3 class="faq__title">Payment Information</h3>
                                    <div class="accordion" id="faqAccordion2">
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq6">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqSix" aria-expanded="true" aria-controls="faqSix">
                                                What does come with my purchase? 
                                            </button>
                                        </h2>
                                        <div id="faqSix" class="accordion-collapse collapse show" aria-labelledby="faqAccordion2" data-bs-parent="#faqAccordion2">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p> When you make a purchase with us, you receive the product(s) you selected during checkout. Additionally, you may receive any applicable warranties, guarantees, or support documentation depending on the nature of the product.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq7">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSev" aria-expanded="true" aria-controls="faqSev">
                                                Are there any recurring fees?
                                            </button>
                                        </h2>
                                        <div id="faqSev" class="accordion-collapse collapse" aria-labelledby="faqAccordion2" data-bs-parent="#faqAccordion2">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p> We do not have any recurring fees associated with your purchase. Our pricing structure is typically one-time, and you'll only be charged for the product(s) you select during checkout.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq8">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqEigh" aria-expanded="true" aria-controls="faqEigh">
                                                Is it possible to customize? 
                                            </button>
                                        </h2>
                                        <div id="faqEigh" class="accordion-collapse collapse" aria-labelledby="faqAccordion2" data-bs-parent="#faqAccordion2">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Currently, we do not offer customization options for our products. However, we're constantly looking to improve our offerings, so please feel free to share any suggestions or specific needs you may have, and we'll take them into consideration for future updates or products. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq9">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqNine" aria-expanded="true" aria-controls="faqNine">
                                                How to get my support?
                                            </button>
                                        </h2>
                                        <div id="faqNine" class="accordion-collapse collapse" aria-labelledby="faqAccordion2" data-bs-parent="#faqAccordion2">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p> We provide customer support to assist you with any questions or issues you may encounter. You can reach our support team through various channels such as email or phone.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq10">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTen" aria-expanded="true" aria-controls="faqTen">
                                                Why should I trust Ecommerce? 
                                            </button>
                                        </h2>
                                        <div id="faqTen" class="accordion-collapse collapse" aria-labelledby="faqAccordion2" data-bs-parent="#faqAccordion2">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Earning your trust is paramount to us. We strive to provide a secure and transparent shopping experience for our customers. Here are a few reasons why you can trust us: <br></br><br></br>
                                                        <b>Secure Payment Processing:</b><br></br> We use industry-standard encryption and security protocols to safeguard your payment information.<br></br>
                                                        <b>Quality Products and Services: </b><br></br>We source our products from reputable suppliers and ensure they meet our quality standards.<br></br>
                                                        <b>Transparent Policies: </b><br></br>We have clear terms of service, refund policies, and privacy policies that govern our interactions with customers.<br></br>
                                                        <b>Customer Feedback:</b><br></br> We value feedback from our customers and continuously strive to improve our products and services based on your suggestions and experiences.<br></br>
                                                        <b>Established Reputation:</b><br></br> We have been serving customers for [number of years] and have built a reputation for reliability and customer satisfaction. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            
            <section class="contact__form-area p-rel">
                <div class="contact__map-wrapper">
                    <div class="contact__map"></div>
                </div>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                            <div class="contact__form  pb-100">
                                <form action="#" class="d-none">
                                    <div class="contact__form-title text-center">
                                        <h3>Still Question?</h3>
                                        <p>Your email address will not be published. </p>
                                    </div>
                                    <div class="contact__form-input">
                                        <input type="text" placeholder="Name *" />
                                    </div>
                                    <div class="contact__form-input">
                                        <input type="email" placeholder="Email *" />
                                    </div>
                                    <div class="contact__form-input">
                                        <textarea placeholder="Comment *"></textarea>
                                    </div>
                                    <button class="contact__form-btn mt-15" type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

            
            <section class="faq__area pt-95 pb-75">
                <div class="container">
                    <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="faq__wrapper">
                                <div class="faq__accordion">
                                    <h3 class="faq__title">Ordering from BRAVE Athleisure</h3>
                                    <div class="accordion" id="faqAccordion4">
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq16">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqSixth" aria-expanded="true" aria-controls="faqSixth">
                                                What courier do you use for deliveries?      
                                            </button>
                                        </h2>
                                        <div id="faqSixth" class="accordion-collapse collapse show" aria-labelledby="faqAccordion4" data-bs-parent="#faqAccordion4">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>We partner with reputable courier services to ensure timely and reliable delivery of your orders. The specific courier used may vary depending on your location and the shipping method selected during checkout. Common couriers we utilize include UPS, FedEx, DHL, and local postal services.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq17">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqSevth" aria-expanded="true" aria-controls="faqSevth">
                                                How long does it take for home delivery?      
                                            </button>
                                        </h2>
                                        <div id="faqSevth" class="accordion-collapse collapse" aria-labelledby="faqAccordion4" data-bs-parent="#faqAccordion4">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>The delivery timeframe depends on various factors, including your location, the shipping method chosen, and the availability of the product(s) you ordered. Typically, standard shipping within Bahrain takes approximately 2 business days from the date of shipment. Expedited shipping options may be available for faster delivery.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq18">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqEith" aria-expanded="true" aria-controls="faqEith">
                                                I placed an order on Friday for next day delivery, why did I not get it until Tuesday?      
                                            </button>
                                        </h2>
                                        <div id="faqEith" class="accordion-collapse collapse" aria-labelledby="faqAccordion4" data-bs-parent="#faqAccordion4">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Orders placed on Fridays for next day delivery are typically processed and shipped on the following business day, which is Monday. Therefore, your order would be expected to arrive on Tuesday, barring any unforeseen delays or exceptions. Please note that weekends and holidays may affect delivery times for next day shipping.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        {/* <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq19">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqNinth" aria-expanded="true" aria-controls="faqNinth">
                                                Why am I being charged for delivery on my order when it states standard delivery is free?      
                                            </button>
                                        </h2>
                                        <div id="faqNinth" class="accordion-collapse collapse" aria-labelledby="faqAccordion4" data-bs-parent="#faqAccordion4">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div> */}
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq20">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwty" aria-expanded="true" aria-controls="faqTwty">
                                                I haven’t received a dispatch email/email confirmation?      
                                            </button>
                                        </h2>
                                        <div id="faqTwty" class="accordion-collapse collapse" aria-labelledby="faqAccordion4" data-bs-parent="#faqAccordion4">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>We apologize for any inconvenience. In some cases, email confirmations or dispatch notifications may be delayed due to technical issues or high order volumes. Please check your spam or junk mail folder, as these emails may sometimes be filtered there. If you still haven't received confirmation after checking these folders, please contact our customer support team with your order details, and we'll be happy to assist you further.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="faq__wrapper faq__wrapper-right">
                                <div class="faq__accordion">
                                    <h3 class="faq__title">Order and returns</h3>
                                    <div class="accordion" id="faqAccordion3">
                                        {/* <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq11">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqEle" aria-expanded="true" aria-controls="faqEle">
                                                Why does it not tell us on the website that the parts will be delivered by the branch?      
                                            </button>
                                        </h2>
                                        <div id="faqEle" class="accordion-collapse collapse show" aria-labelledby="faqAccordion3" data-bs-parent="#faqAccordion3">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div> */}
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq12">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqTwl" aria-expanded="true" aria-controls="faqTwl">
                                                I'm new, how do I order?      
                                            </button>
                                        </h2>
                                        <div id="faqTwl" class="accordion-collapse collapse" aria-labelledby="faqAccordion3" data-bs-parent="#faqAccordion3">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Welcome! Ordering from our website is easy. Simply browse our product Page, select the items you wish to purchase, and add them to your shopping cart. Once you're ready to checkout, follow the prompts to enter your shipping and payment information. If you have any questions or need assistance at any step of the process, feel free to reach out to our customer support team for guidance. </p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq13">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqThir" aria-expanded="true" aria-controls="faqThir">
                                                Is it safe to order online?      
                                            </button>
                                        </h2>
                                        <div id="faqThir" class="accordion-collapse collapse" aria-labelledby="faqAccordion3" data-bs-parent="#faqAccordion3">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>Yes, it is safe to order online from our website. We prioritize the security of your personal and payment information and use industry-standard encryption and security protocols to protect your data. Additionally, we do not store your credit card information on our servers after the transaction is complete, further enhancing the security of your information.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq14">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqfourth" aria-expanded="true" aria-controls="faqfourth">
                                                Which credit cards do you accept?      
                                            </button>
                                        </h2>
                                        <div id="faqfourth" class="accordion-collapse collapse" aria-labelledby="faqAccordion3" data-bs-parent="#faqAccordion3">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>We accept a variety of major credit cards for payment, including Visa, Mastercard, American Express, and Discover. You can select your preferred payment method during the checkout process and securely enter your credit card details to complete your purchase.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="accordion-item">
                                        <h2 class="accordion-header" id="faq15">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqFivth" aria-expanded="true" aria-controls="faqFivth">
                                                What currencies can I use?      
                                            </button>
                                        </h2>
                                        <div id="faqFivth" class="accordion-collapse collapse" aria-labelledby="faqAccordion3" data-bs-parent="#faqAccordion3">
                                            <div class="accordion-body">
                                                <div class="faq__content">
                                                    <p>We accept payment in multiple currencies to accommodate our international customers. The available currencies may vary depending on your location and the payment processor used during checkout. You can typically select your preferred currency from a dropdown menu or currency converter during the checkout process. If you have any specific currency-related questions, please feel free to contact our customer support team for assistance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

        </main>

    </Fragment>
  )
}

export default Faq