import React from 'react';
import './Contact.css';
import about_us_bg_1 from "../../assets/img/about/about-us-bg_1.jpg";

const Contact = () => {
    
  document.title = 'Contact | BRAVE Athleisure';

  return (
    
    <div>

      <main>

        <section class="page__title-area pt-80 pb-65">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xxl-8 col-xl-10">
                        <div class="page__title-wrapper text-center">
                            <span class="page__title-pre">Hi, BRAVE Athleisure Store</span>
                            <h3 class="page__title">Contact</h3>
                            <p>This is how you can contact us! We are eager to listen to your enquiries, complaints, and notes on all things BRAVE Athleisure. Let’s talk!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="about__banner">
            <div class="container-fluid p-0">
                <div class="row gx-0">
                    <div class="col-xxl-12">
                        <div class="about__banner-thumb w-img">
                            <img src={about_us_bg_1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="contact__area pb-60 pt-90">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-10">
                        <div class="contact__wrapper">
                            <div class="contact__title text-center mb-50">
                                <span>Contact Us</span>
                                <h3> 
                                    <span>Hello, We are BRAVE Athleisure</span> <br/>
                                    Contact Us For Any Questions
                                </h3>
                                    {/* <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis. For me, the most important part of improving at photography has been sharing it</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 mx-auto">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="contact__address-item">
                                    <div class="contact__address-thumb w-img">
                                        <img src="assets/img/contact/contact-thumb-img-2.jpg" alt=""/>
                                    </div>
                                    <div class="contact__address-content">
                                        <div class="contact__address-flag d-flex align-items-center">
                                            <div class="contact-flag mr-15">
                                                <img src="assets/img/contact/flag-2.jpg" alt=""/>
                                            </div>
                                            <div class="contact-flag-content">
                                                <p>BRAVE Athleisure Store</p>
                                                <h5>Avenues Mall, Bahrain</h5>
                                            </div>
                                        </div>
                                        {/* <ul>
                                            <li>Add: <a href="https://www.google.com/maps/place/The+British+Museum/@51.5194166,-0.1291453,17z/data=!3m1!4b1!4m5!3m4!1s0x48761b323093d307:0x2fb199016d5642a7!8m2!3d51.5194133!4d-0.1269566?hl=en" target="_blank">S9 Heaven Stress, Beverly Hill, USA.</a></li>
                                            <li>Phone: <a href="tel:123-455-789">+1 (800) 123 456 789</a></li>
                                            <li>Email: <a href="mailto:info@braveathleisure.com"><span class="__cf_email__" data-cfemail="d192bebfa5b0b2a591a5b9b4bcb4a1a4a3b4ffb2bebc">info@braveathleisure.com</span></a></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="contact__address-item">
                                    <div class="contact__address-thumb w-img">
                                        <img src="assets/img/contact/contact-thumb-img-3.jpg" alt=""/>
                                    </div>
                                    <div class="contact__address-content">
                                        <div class="contact__address-flag d-flex align-items-center">
                                            <div class="contact-flag mr-15">
                                                <img src="assets/img/contact/flag-3.jpg" alt=""/>
                                            </div>
                                            <div class="contact-flag-content">
                                                <p>BRAVE Athleisure Store</p>
                                                <h5>Dubai</h5>
                                            </div>
                                        </div>
                                        {/* <ul>
                                            <li>Add: <a href="https://www.google.com/maps/place/The+British+Museum/@51.5194166,-0.1291453,17z/data=!3m1!4b1!4m5!3m4!1s0x48761b323093d307:0x2fb199016d5642a7!8m2!3d51.5194133!4d-0.1269566?hl=en" target="_blank">Road-2830, Block-428, Building-2304, Office-71, 72, Seef, BAHRAIN</a></li>
                                            <li>Phone: <a href="tel:123-455-789"> (+973) 1700 0038 </a></li>
                                            <li>Email: <a href="mailto:info@braveathleisure.com"><span class="__cf_email__" data-cfemail="33705c5d4752504773475b565e56434641561d505c5e">info@braveathleisure.com</span></a></li>
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="contact__form-area p-rel">
            <div class="contact__map-wrapper">
                <iframe class="contact__map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.7697700111453!2d50.597330314673734!3d26.26913458340892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49a8a75edcfe27%3A0xa9000f3479626749!2sBrave+Combat+Federation!5e0!3m2!1sen!2sbh!4v1557828245975!5m2!1sen!2sbh"></iframe>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10">
                        <div class="contact__form black-bg-3">
                            <form action="#">
                                <div class="contact__form-title text-center">
                                    <h3>Still Question?</h3>
                                    <p>Your email address will not be published. </p>
                                </div>
                                <div class="contact__form-input">
                                    <input type="text" placeholder="Name *" />
                                </div>
                                <div class="contact__form-input">
                                    <input type="email" placeholder="Email *" />
                                </div>
                                <div class="contact__form-input">
                                    <textarea placeholder="Comment *"></textarea>
                                </div>
                                <button class="contact__form-btn mt-15" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      </main>

    </div>

  )
}

export default Contact