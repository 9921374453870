import React, { Fragment, useState, useEffect } from "react";
import "./UpdateProfile.css";
import Loader from "../layout/Loader/Loader";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useNavigate } from "react-router-dom";
import FaceIcon from "@material-ui/icons/Face";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, loadUser } from "../../actions/userAction";
// import { useAlert } from "react-alert";
import { UPDATE_PROFILE_RESET } from "../../constants/userConstant.js";
import MetaData from "../layout/Metadata";
import { toast } from 'react-toastify';

const UpdateProfile = () => {
  const dispatch = useDispatch();
//   const alert = useAlert();

 let navigate = useNavigate();

  const { user } = useSelector((state) => state?.user);
  const { error, isUpdated, loading } = useSelector((state) => state?.profile);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState("/Profile.png");

  const updateProfileSubmit = (e) => { 
    e.preventDefault();
    try{
      const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email); 
    myForm.set("avatar", avatar);
    dispatch(updateProfile(myForm));
    }
    catch(error){
      toast.error("Select Profile picture", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
    
  };

  const updateProfileDataChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader?.readyState === 2) {
        setAvatarPreview(reader?.result);
        setAvatar(reader?.result);
      }
    };

    reader.readAsDataURL(e?.target?.files[0]);
  };

  useEffect(() => {
    if (user) {
      setName(user?.name);
      setEmail(user?.email);
      setAvatarPreview(user?.avatar?.url);
    }

    // if (error) {
    //   alert?.error(error);
    //   dispatch(clearErrors());
    // }

    if (isUpdated) {
    //   alert.success("Profile Updated Successfully");
      dispatch(loadUser());

      navigate("/profile");

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, error, navigate, user, isUpdated]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Update Profile" />
          <div className="updateProfileContainer">
            <div className="updateProfileBox">
              <h4 className="updateProfileHeading">Update Profile</h4>

              <form
                className="updateProfileForm"
                encType="multipart/form-data"
                onSubmit={updateProfileSubmit}
              >
                <div className="updateProfileName">
                  <FaceIcon />
                  <input
                    type="text"
                    placeholder="Name"
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e?.target?.value)}
                  />
                </div>
                <div className="updateProfileEmail">
                  <MailOutlineIcon />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value)}
                  />
                </div>

                <div id="updateProfileImage">
                  <img src={avatarPreview} alt="Avatar Preview" />
                  <input
                    type="file"
                    name="avatar"
                    accept="image/*"
                    onChange={updateProfileDataChange}
                  />
                </div>
                <input
                  type="submit"
                  value="Update"
                  className="updateProfileBtn s-btn s-btn-2"
                />
              </form>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdateProfile;